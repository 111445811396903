import React from "react";
import {
  Left,
  Right,
  Wrapper,
  InnerWrapper,
} from "./styles";

function Footer() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Left> © {new Date().getFullYear()} Swimteam </Left>
        <Right>
          <a href="mailto:hello@swimteam.app">Contact </a>
        </Right>
      </InnerWrapper>
    </Wrapper>
  );
}

export default Footer;
