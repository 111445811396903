import React, { useState } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ProgressiveMessage from "components/ProgressiveMessage";

import {
  Field,
  Header,
  Title,
  Description,
  Wrapper,
  Input,
  Button,
  InnerWrapper,
  Divider,
  Wave,
  Step,
} from "./styles";

const SUBSCRIBE_QUERY = gql`
    mutation Subscribe(
      $email: String
    ) {
      subscribe(
        email: $email
      ) {
        email
      }
    }
  `;

function Signup() {
  const [step, setStep] = useState(1);
  const [done, setDone] = useState();
  const [email, setEmail] = useState();

  const [subscribe] = useMutation(SUBSCRIBE_QUERY, {
    onError: (error) => {
      // alert(error.message);
    },
  });

  const doSubscribe = () => {
    if (!email) return;

    setTimeout(() => {
      setDone(true);

      setTimeout(() => {
        setStep(2);
        subscribe({ variables: {
          email,
        } });
      }, 1000);
    }, 500);
  };

  const onInputKeyDown = (e) => {
    if (e.key === "Enter") doSubscribe();
  };

  return (
    <Wrapper done={done}>
      <InnerWrapper>
        {/*
        <Step visible={step === 1}>
          <div>
            <Header>
              <Title>Get notified of the beta</Title>
            </Header>
            <Field>
              <Field.Left>
                <Input
                  placeholder="type an email address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyDown={onInputKeyDown}
                  required
                  type="email"
                />
              </Field.Left>
              <Field.Right>
                <Button
                  disabled={!email}
                  onClick={doSubscribe}
                >
                  <span>
                    <ArrowForwardIcon />
                  </span>
                </Button>
              </Field.Right>
            </Field>
          </div>
        </Step>
        <Step visible={step === 2}>
          <div>
            <Header>
              <Title>Welcome to the team!</Title>
            </Header>
            <Description>
              We'll let you know when it's time to test the waters!
            </Description>
            <Divider />
          </div>
        </Step>
        */}
        <Wave />
      </InnerWrapper>
    </Wrapper>
  );
}

export default Signup;
