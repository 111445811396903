/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import "./style.css";

class Message extends React.Component {
  state = {
    refs: [],
    done: false,
    play: false,
    charIndex: 0
  }

  covertTypes = [
    "span",
    "strong",
    "em",
    "p",
    "h3",
  ]

  shouldComponentUpdate(nextProps, nextState) {
    //return (nextState.done !== this.state.done) || (nextState.active !== this.state.active)
    return true
  }

  componentDidUpdate() {
  }

  speak(message) {
  }


  componentDidMount() {
    //let index = 0;

    //this.interval = setInterval(() => {
      //if (index < this.state.refs.length) {
        //this.state.refs[index].className += " visible";
        //index++;
      //} else {
        //this.setState({ done: true });
        //clearInterval(this.interval);
      //}
    //}, 200);
  }

  componentWillUnmount() {
    this.setState({ done: true });
    clearInterval(this.interval);
  }

  renderFragment = (children = []) => React.Children.map(children, childNode => {
    let newCharIndex = this.state.charIndex

    if (typeof childNode === "string") {
      return childNode.split("").map((char, index) => {
        newCharIndex++

        return (
          <span
            key={`${index}_${char}`}
            ref={span => this.state.refs.push(span)}
            className="letter"
            style={{ animationDelay: this.props.delay + (20 * newCharIndex) + 'ms' }}
          >
            {char}
          </span>
        )
      });
    } else if (this.covertTypes.includes(childNode.type)) {
      return React.cloneElement(
        childNode,
        [],
        this.renderFragment(childNode.props.children)
      );
    } else if (typeof childNode.type === "function") {
      const renderedChildNode = childNode.type(childNode.props);

      if (typeof renderedChildNode === "string") {
        return this.renderFragment([renderedChildNode]);
      }
      return React.cloneElement(
        renderedChildNode,
        [],
        this.renderFragment(renderedChildNode.props.children)
      );
    }

    this.setState({ charIndex: newCharIndex})

    return childNode;
  })

  render() {
    console.log('active', this.props.active)
    return (
      <React.Fragment>
        {this.props.active && this.renderFragment(this.props.children)}
      </React.Fragment>
    );
  }
}

export default Message;
