import { css } from 'styled-components';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const sizes = {
  desktop: [900, 1280],
  tablet: [600, 899],
  mobile: [0, 599]
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label, idx, arr) => {
  // "belowDesktop"
  acc['below' + capitalize(label)] = (...args) => css`
    @media (max-width: ${sizes[label][0] -1}px) {
      ${css(...args)}
    }
   `

  // "aboveDesktop"
  acc['above' + capitalize(label)] = (...args) => css`
    @media (min-width: ${sizes[label][1] + 1}px) {
      ${css(...args)}
    }
  `

  // "desktopAndUp"
  acc[label + 'AndUp'] = (...args) => css`
    @media (min-width: ${sizes[label][0]}px) {
      ${css(...args)}
    }
  ` 

  // "desktopAndBelow"
  acc[label + 'AndBelow'] = (...args) => css`
    @media (max-width: ${sizes[label][1]}px) {
      ${css(...args)}
    }
  `

  // "desktop"
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[label][1]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default media;