import React from "react";
import { Wrapper, Wave } from "./styles";


const WaveComponent = (props) => (
  <Wrapper {...props}>
    <Wave />
    <Wave />
  </Wrapper>
);


export default WaveComponent;
