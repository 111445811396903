// import Content from "./Content";


import {
  Wrapper,
  Main,
  Side,
  Heading,
  HeadingIcon,
  Description,
  Image,
} from "./styles";

export default Wrapper;

Wrapper.Main = Main;
Wrapper.Side = Side;
Wrapper.Heading = Heading;
Wrapper.HeadingIcon = HeadingIcon;
Wrapper.Description = Description;
Wrapper.Image = Image;
