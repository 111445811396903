import "assets/App.css";
import React, { Component } from "react";
// import { hot } from "react-hot-loader/root";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
// export default hot(App);
