import styled from "styled-components";
import media from "utils/mediaQuery";

export const Wrapper = styled.div`
  color: #1F296F;
  padding: 0 20px;
  margin: 0 -20px;
  position: relative;
  z-index: 1;
`;

export const InnerWrapper = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: auto;

  ${media.mobile`
    padding: 30px 10px
  `}

  ${media.tabletAndUp`
    padding: 30px 30px;
  `}
`;

export const Left = styled.div` `;

export const Right = styled.div` `;

