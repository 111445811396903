import styled from "styled-components";

export const Wrapper = styled.div`
  width:100%;
  position:absolute;
  bottom: 0;
  height: 800px;
  left:0;
  opacity: .2
`;

export const Wave = styled.div`
  background: url(${require("./wave.png")}) repeat-x;
  position: absolute;
  background-position: left top;
  top: 45px;
  width: 6400px;
  height: 800px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  background-repeat: repeat-x;
  background-position: left bottom;

  &:nth-of-type(2) {
    top: 25px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @keyframes swell {
    0%, 100% {
      transform: translate3d(0,-25px,0);
    }
    50% {
      transform: translate3d(0,5px,0);
    }
  }
`;
