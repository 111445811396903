import React from "react";
import styled from "styled-components";
import media from "utils/mediaQuery";

const OuterWrapper = styled.div.attrs({
  className: p => (p.alt ? "alt" : ""),
})`
  position: relative;
  z-index: 1;

  ${p => p.alt && `
    &:after {
      background: #EBF2FF;
      content: '';
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
    }
  `}

  ${media.mobile`
    padding: 60px 0
  `}

  ${media.tablet`
    padding: 80px 0
  `}

  ${media.desktopAndUp`
    padding: 150px 0;
  `}
`;

const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;

  ${media.desktopAndUp`
    max-width: 1000px;
    padding: 0 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${media.tablet`
    max-width: 600px;
    margin: 0 auto;
    padding: 0 40px;
  `}

  ${media.mobile`
    padding: 0 20px;
  `}
`;

export const Wrapper = ({ alt, children }) => (
  <OuterWrapper alt={alt}>
    <InnerWrapper>
      {children}
    </InnerWrapper>
  </OuterWrapper>
);

export const Main = styled.div`
  ${media.mobile`
    &:not(:last-child) {
     margin-bottom: 60px;
    }
  `}

  ${media.tablet`
    margin-bottom: 60px;
  `}

  ${media.desktopAndUp`
    &:not(:last-child) {
      width: 400px;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 60px 0 0;
    }

    ${OuterWrapper}.alt & {
      order: 1;
      margin: 0 0 0 60px
    }
  `}

`;

export const Heading = styled.div`
  font-size: 36px;
  margin-bottom: 20px;
  color: #1F296F;

  ${media.mobile`
    font-size: 28px;
  `}

  ${media.tablet`
    text-align: center;
  `}
`;

export const HeadingIcon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: 0 0 15px 0 rgba(102,102,102,0.30);
  display: inline-block;
  display: none;

`;

export const Description = styled.div`
  font-size: 18px;
  etter-spacing: 0;
  line-height: 30px;

  p:not(:last-child) {
    margin-bottom: 30px
  }
`;

export const Side = styled.div`
  flex-grow: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 1;
`;

export const Image = styled.div`
  border-radius: 10px;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;

  img {
    vertical-align: top;
    width: 100%;
  }
`;
