import styled from "styled-components";
import media from "utils/mediaQuery";

export const Wrapper = styled.div`
  -webkit-font-smoothing: antialiased;
  background-image:  url(${require("./background.svg")}), linear-gradient(#3C2891 0, #3C2891 50%, transparent 50%, transparent 100%);
  background-position: center bottom 1px ;
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: 0 auto;
  overflow: hidden;

  ${media.mobile`
    height: 300px;
    border-radius: 10px 10px 0 0;
    background-size: 100% auto;
    margin-bottom: -20px;
    background-image: url(${require("./background-mobile.svg")});
  `}

  ${media.tablet`
    height: 400px;
  `}

  ${media.tabletAndUp`
    max-width: 1400px;
    border-radius: 20px 20px 0 0;
  `}

  ${media.desktopAndUp`
    height: 600px;
  `}
`;

export const Top = styled.div`
  display: flex;  
  justify-content: space-between;
  padding: 20px 20px;
`;

Top.Left = styled.div` `;
Top.Right = styled.div` `;

export const Title = styled.div`
  color: white;
  font-family: "Fredoka One";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 2;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;

  ${media.mobile`
    font-size: 20px;
  `}
`;

export const Main = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: -100px;

  ${media.mobile`
    margin-top: -60px
  `}

  ${media.tablet`
    margin-top: -80px
  `}

  ${media.desktopAndUp`
    margin-top: -90px;
  `}
`;

export const Description = styled.div`
  color: #FFFFFF;
  letter-spacing: 1.1px;
  line-height: 1.3;
  text-align: center;

  ${media.mobile`
    font-size: 20px
  `}

  ${media.tablet`
    font-size: 30px;
  `}

  ${media.desktopAndUp`
    font-size: 36px;
  `}
`;

export const Navigation = styled.div``;

export const NavigationButton = styled.a`
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 17px 0 rgba(0,0,0,0.08);
  display: block;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-align: center;
  transition: background 200ms;

  &:hover {
    background: #eee;
  }
`;
