import styled from "styled-components";
import media from "utils/mediaQuery";

export const Wrapper = styled.div`
  padding: 20px 20px 0;
  margin: auto;
  overflow: hidden;
  //max-width: 1400px;

  ${media.mobile`
    padding: 10px 10px 0;
    overflow: hidden;
  `}
`;

export const Main = styled.div`
  position: relative;
  //z-index: 1
`;

export const AltContainer = styled.div`
  margin: 0 -20px;
  padding: 0 20px;
  position: relative;
  background-color: #EBF2FF;

  ${media.mobile`
  `}
`;
