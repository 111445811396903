import styled from "styled-components";
import WaveBase from "components/Wave";
import media from "utils/mediaQuery";

export const Wrapper = styled.div.attrs({
  className: p => (p.done ? "done" : ""),
})`
  position: relative;
  z-index: 1;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 100px 0;
  flex-wrap: wrap;
      background-image: url(${require("./background.svg")}), linear-gradient(transparent 0, transparent 50%, #602AA6 50%, #602AA6 100%);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  margin: 0 20px;
  border-radius: 0 0 10px 10px;
  color: #EBF2FF;
  max-width: 1400px;
  margin: auto;
  position: relative;
  overflow: hidden;

  ${media.mobile`
    padding: 75px 20px;
    background-image: url(${require("./background-mobile.svg")});
  `}

  ${media.tablet`
    padding: 100px 0;
  `}

  ${media.desktopAndUp`
    padding: 100px 0;
  `}
`;

export const Step = styled.div.attrs({
  className: p => (p.visible ? "visible" : ""),
})`
  display: none;
  height: 200px;
  align-items: center;
  justify-content: center;
  transition: all 200ms;
  opacity: .8;
  position: relative;

  ${p => p.visible && `
    display: flex;
    opacity: 1;
    animation-delay: 3s;
  `}
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
  
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  ${media.mobile`
    &:not(:last-child) {
      margin-bottom: 16px
    }
  `}
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 300;
  transition: all 200ms;

  ${media.mobile`
    font-size: 24px
  `}
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    display: block;
    height: 4px;
    background: #8B41D2;
    max-width: 40px;
    opacity: 0;
    border-radius: 3px;
    transition: all 3s;
    transition-delay: 2s;
    flex-grow: 1;
    flex-shrink: 1;

    ${Step}.visible & {
      max-width: 100px;
      opacity: 1;
    }
  }
`;

export const Description = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  font-weight: 300;
  max-width: 300px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 3s;

  ${Step}.visible & {
    opacity: 1;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  ${media.mobile`
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
  `}
`;

export const Field = styled.div.attrs({
  className: p => (p.done ? "done" : ""),
})`
  display: flex;
`;

Field.Left = styled.div`
  flex-grow: 0;
  flex-shrink: 1;

  ${media.mobile`
    margin-right: 10px;
  `}

  ${media.tabletAndUp`
    margin-right: 20px;
  `}
`;

Field.Right = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
`;

export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  height: 80px;
  width: 540px;
  max-width: 100%;
  font-size: 36px;
  padding: 0 35px;
  color: #666666;

  &::placeholder {
    color: #ddd;
  }

  ${media.mobile`
    font-size: 16px;
    padding: 0 15px;
    height: 50px;
    width: 250px;
    border-radius: 8px;
    width: 100%;
  `}
`;

export const Button = styled.button`
  height: 80px;
  width: 80px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background: #8B41D2;
  box-shadow: 0 0 13px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  transition: all 200ms;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:active {
    transform: scale(.96);
  }

  &:hover {
   background: #AC64F2;
  }

  &:disabled {
    background: #999;
    cursor: not-allowed
  }

  ${media.mobile`
    height: 50px;
    width: 50px;
    border-radius: 10px;

    svg {
      font-size: 30px !important;
      vertical-align: middle
    }
  `}

  ${media.tabletAndUp`
    svg {
      font-size: 60px;
      vertical-align: middle
    }
  `}
`;

export const Wave = styled(WaveBase)`
  transition: all 400ms;
  opacity: 1;

  ${media.tabletAndUp`
    bottom: -600px;

    ${Wrapper}.done & {
      animation: updown 2s;
    }

    @keyframes updown {
      0% { bottom: -600px; }
      25% { bottom: -200px; }
      50% { bottom: -200px; }
      100% { bottom: -600px; }
    }
  `}

  ${media.mobile`
    transform: scale(.5) translateX(-50%);
    bottom: -459px;

    ${Wrapper}.done & {
      animation: updown 2s;
    }

    @keyframes updown {
      0% { bottom: -450px; }
      25% { bottom: -280px; }
      50% { bottom: -280px; }
      100% { bottom: -450px; }
    }
  `}
`;
