import React from "react";
import {
  Top,
  Main,
  Title,
  Wrapper,
  Navigation,
  Description,
  NavigationButton,
} from "./styles";

function Hero() {
  return (
    <Wrapper>
      <Top>
        <Top.Left>
          <Title>Swimteam</Title>
        </Top.Left>
        <Top.Right>
          <Navigation>
            <NavigationButton a href="https://app.swimteam.app/auth/login">
              Login
            </NavigationButton>
          </Navigation>
        </Top.Right>
      </Top>
      <Main>
        <Description>
          project management<br />
          for small teams and<br />
          solo-preneurs
        </Description>
      </Main>
    </Wrapper>
  );
}

export default Hero;
