import React from "react";
import Hero from "components/Hero";
import Footer from "components/Footer";
import Signup from "components/Signup";
import Wave from "components/Wave";
import Content from "components/Content";
import {
  Main,
  Wrapper,
  AltContainer,
} from "./styles";

export function MainPage() {
  return (
    <Wrapper>
      <Main>
        <Hero />
        <Content>
          <Content.Main align="right">
            <Content.Heading>
              <Content.HeadingIcon /> A better way to plan
            </Content.Heading>
            <Content.Description>
              <p>
                With our hiearchical project planning, organize your projects and
                tasks in a way that makes sense. See at a glance how each and every
                part of your project is related and prioritized.
                No more kanban, no more crazy card clutter.
              </p>
              <p>
                With Swimteam, gain a sense of clarity and purpose that puts you right into the action, right where you need to be.
                Spend less time trying to decide what to work on and more time crushing it.
              </p>
            </Content.Description>
          </Content.Main>
          <Content.Side>
            <Content.Image>
              <img
                src={require("./images/plan_better2.png")}
                alt="Better Planning"
              />
            </Content.Image>
          </Content.Side>
        </Content>
        <Content alt>
          <Content.Main>
            <Content.Heading>A better way to schedule</Content.Heading>
            <Content.Description>
              <p>
                Adaptive timebox scheduling lets your projects work around your
                schedule, not the other way around. Define when and what you want
                to work on, and we’ll automatically create your daily agenda,
                work schedule, and deadlines. If something urgent comes up,
                you can always bump a task ahead into the future and watch
                everything else reshuffle.
              </p>

              <p>Swimteam helps you to be realistic, honor your intentions, and keep moving forward.</p>
            </Content.Description>
          </Content.Main>
          <Content.Side>
            <Content.Image>
              <img src={require("./images/plan_better.png")} alt="Better Scheduling" />
            </Content.Image>
          </Content.Side>
        </Content>
        <Content>
          <Content.Main>
            <Content.Heading>A better way to work</Content.Heading>
            <Content.Description>
              <p>
                With our clutter-free work mode, you can work distraction-free,
                one task at a time. We don't overload you with integrations,
                plug-ins, and excessive customization. We removed the noise from
                our UI, so that you can remove the noise from your work and
                organization.
              </p>
              <p>We help you to define your project, its tasks, and subtasks,
                then filter everything else out.
              </p>
            </Content.Description>
          </Content.Main>
          <Content.Side>
            <Content.Image>
              <img src={require("./images/work_better.png")} alt="Better Working" />
            </Content.Image>
          </Content.Side>
        </Content>
        <Content alt>
          <Content.Main>
            <Content.Heading>More time for you</Content.Heading>
            <Content.Description>
              <p>
                Our goal is to give you delightful tools to be productive
                and enjoy the type of work/life balance that works for you.
              </p>
            </Content.Description>
          </Content.Main>
          <Content.Side>
            <Content.Image>
              <img src={require("./images/relax_better.png")} alt="Better Relaxing" />
            </Content.Image>
          </Content.Side>
        </Content>
        <AltContainer>
          <Signup />
          <Footer />
        </AltContainer>
      </Main>
    </Wrapper>
  );
}

export default MainPage;
