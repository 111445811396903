import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { hasSubscription } from "@jumpn/utils-graphql";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink, ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Socket as PhoenixSocket } from "phoenix";

const http_endpoint = () => (process.env.NODE_ENV === "development"
  ? "//localhost:4000/api"
  : `//${location.hostname}/backend/api`);

const ws_endpoint = () => (process.env.NODE_ENV === "development"
  ? "//localhost:4000/socket"
  : `//${location.hostname}/backend/socket`);

// Create an HTTP link to the Phoenix app's HTTP endpoint URL.
const httpLink = new HttpLink({
  uri: http_endpoint(),
});

// Create a WebSocket link to the Phoenix app's socket URL.
const socketLink = createAbsintheSocketLink(
  AbsintheSocket.create(new PhoenixSocket(ws_endpoint()))
);

// If an authentication token exists in local storage, put
// the token in the "Authorization" request header.
// Returns an object to set the context of the GraphQL request.
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create a link that "splits" requests based on GraphQL operation type.
// Queries and mutations go through the HTTP link.
// Subscriptions go through the WebSocket link.
// eslint-disable-next-line
const link = new ApolloLink.split(
  operation => hasSubscription(operation.query),
  socketLink,
  authLink.concat(httpLink)
);

// Create the Apollo Client instance.
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
